import Glide from '@glidejs/glide'

new Glide('.glide',{
  type: 'peek',
  gap: 60,
  autoplay: 3000,
  startAt: 1,
  perView: 1,
  peek: {
    before: 120,
    after: 120
  },
  breakpoints: {
    992: {
      peek: {
        before: 75,
        after: 75
      },
      gap: 40,
    },
    768: {
      peek: {
        before: 50,
        after: 50
      },
      gap: 20,
    }
  }
}).mount();